import React from "react"

export default () => (
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="container">
        <div className="row">
                <div className="col-12 col-md">
                    <p className="copyright text-muted">
                        Copyright © { (new Date()).getFullYear() } | A.O.M.
                    </p>
                </div>
            </div>
      </div>
    </footer>
)