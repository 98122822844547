import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageBackground from "../img/hero-image-short-dark-bottom.png"

const Fragment = React.Fragment;

const Body = () => (
<div className="container body-content">
  <div className="row">
    <div className="col-xs-12">
      <h2>Oh no! That page cannot be found.</h2>
    </div>
  </div>
</div>
)

export default () => (
  <Fragment>
    <Header pageType="page" 
            pageTitle="A.O.M."
            headerImg={PageBackground} />
    <Body />
    <Footer />
  </Fragment>
)