import React from "react"
import { Link } from "gatsby"
import $ from "jquery";

const Fragment = React.Fragment;

class Header extends React.Component {
  componentDidMount() {    
    $(function() {
      // Floating label headings for the contact form
      $("body")
        .on("input propertychange", ".floating-label-form-group", function(e) {
          $(this).toggleClass(
            "floating-label-form-group-with-value",
            !!$(e.target).val()
          );
        })
        .on("focus", ".floating-label-form-group", function() {
          $(this).addClass("floating-label-form-group-with-focus");
        })
        .on("blur", ".floating-label-form-group", function() {
          $(this).removeClass("floating-label-form-group-with-focus");
        });

      // Show the navbar when the page is scrolled up
      var MQL = 992;

      //primary navigation slide-in effect
      if ($(window).width() > MQL) {
        var headerHeight = $("#mainNav").height() || 50;
        $(window).on("scroll", { previousTop: 0 }, function() {
            var currentTop = $(window).scrollTop();
            //check if user is scrolling up
            if (currentTop < this.previousTop) {
              //if scrolling up...
              if (currentTop > 0 && $("#mainNav").hasClass("is-fixed")) {
                $("#mainNav").addClass("is-visible");
              } else {
                $("#mainNav").removeClass("is-visible is-fixed");
              }
            } else if (currentTop > this.previousTop) {
              //if scrolling down...
              $("#mainNav").removeClass("is-visible");
              if (currentTop > headerHeight && !$("#mainNav").hasClass("is-fixed"))
                $("#mainNav").addClass("is-fixed");
            }
            this.previousTop = currentTop;
          }
        );
      }
    });
  }

  render() { return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div className="container">
          <Link className={"navbar-brand"} to="/">A.O.M.</Link>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation" >Menu <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                  <Link className={"nav-link"} to="/">Home</Link>
              </li>
              <li className="nav-item">
                  <Link className={"nav-link"} to="/contact/">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header className="masthead" style={{ backgroundImage: 'url(' + this.props.headerImg + ')' }}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <div className={this.props.pageType + "-heading"}>
                <h1>{this.props.pageTitle}</h1>
                <hr className="small" />
                <span className="subheading">{this.props.pageSubTitle}</span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
  }
}

export default Header;